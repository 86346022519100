<template>
  <!-- Authors Table Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">Data Publisher</h5>
        </a-col>
        <a-col
          :span="24"
          :md="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
          <a-radio-group
            v-model="dataType"
            size="small"
            @change="filterMarket()"
          >
            <a-radio-button value="all">ALL</a-radio-button>
            <a-radio-button value="market">MARKET</a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
    </template>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nama Penerbit</th>
            <th class="text-left">Penanggung Jawab</th>
            <th class="text-left">Jumlah Naskah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              <div class="table-avatar-info">
                <a-avatar shape="square" :src="item.avatar" />
                <div class="avatar-info">
                  <h6>{{ item.type }} {{ item.name }}</h6>
                  <p>{{ item.email }}</p>
                </div>
              </div>
            </td>

            <td>
              <div class="table-avatar-info">
                <div class="avatar-info">
                  <h6>{{ item.penanggung_jawab }}</h6>
                  <p>{{ item.phone }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ item.naskah_count }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </a-card>
  <!-- / Authors Table Card -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      dataType: "all",
    };
  },
  methods: {
    filterMarket() {
      this.$emit("filter", this.dataType);
      console.log(this.dataType);
    },
  },
};
</script>
