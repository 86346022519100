<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">

			<!-- Authors Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Authors Table Card -->
				<CardPublisherTable
					:data="items.data"
					:columns="table1Columns"
          			@filter="tipe"
				></CardPublisherTable>
				<!-- / Authors Table Card -->

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->

		<div class="mx-5 text-center">
			<a-pagination v-model="page" :total="items.last_page * 10"
			@change="getData"/>
		</div>

	</div>
</template>

<script>
	import axios from 'axios';
	import CardPublisherTable from '../components/Cards/CardPublisherTable' ;
	
	// "Authors" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'TITLE',
			dataIndex: 'author',
			scopedSlots: { customRender: 'author' },
		},
		{
			title: 'PUBLISHER',
			dataIndex: 'func',
			scopedSlots: { customRender: 'func' },
		},
		{
			title: 'MARKET',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
		},
		{
			title: 'DATE',
			dataIndex: 'employed',
			class: 'text-muted',
		},
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [
		{
			key: '1',
			author: {
				avatar: 'images/face-2.jpg',
				name: 'Michael John',
				email: 'michael@mail.com',
			},
			func: {
				job: 'Manager',
				department: 'Organization',
			},
			status: 1,
			employed: '23/04/18',
		},
		{
			key: '2',
			author: {
				avatar: 'images/face-3.jpg',
				name: 'Alexa Liras',
				email: 'alexa@mail.com',
			},
			func: {
				job: 'Programator',
				department: 'Developer',
			},
			status: 0,
			employed: '23/12/20',
		},
		{
			key: '3',
			author: {
				avatar: 'images/face-1.jpg',
				name: 'Laure Perrier',
				email: 'laure@mail.com',
			},
			func: {
				job: 'Executive',
				department: 'Projects',
			},
			status: 1,
			employed: '13/04/19',
		},
		{
			key: '4',
			author: {
				avatar: 'images/face-4.jpg',
				name: 'Miriam Eric',
				email: 'miriam@mail.com',
			},
			func: {
				job: 'Marketing',
				department: 'Organization',
			},
			status: 1,
			employed: '03/04/21',
		},
		{
			key: '5',
			author: {
				avatar: 'images/face-5.jpeg',
				name: 'Richard Gran',
				email: 'richard@mail.com',
			},
			func: {
				job: 'Manager',
				department: 'Organization',
			},
			status: 0,
			employed: '23/03/20',
		},
		{
			key: '6',
			author: {
				avatar: 'images/face-6.jpeg',
				name: 'John Levi',
				email: 'john@mail.com',
			},
			func: {
				job: 'Tester',
				department: 'Developer',
			},
			status: 0,
			employed: '14/04/17',
		},
	];

	export default ({
		components: {
			CardPublisherTable,
		},
		data() {
			return {
				table1Data: table1Data,
				table1Columns: table1Columns,

				type: 'all',
				items: [],
				page: 1
			}
		},

		mounted(){
			this.getData();
		},
		methods:{
			tipe: function (str) {
				this.type = str;
				this.getData()
			},
			getData(){
				axios({
					method: 'get',
					url: 'admin/publisher?page='+this.page + "&type=" + this.type,
				}).then((response) => {
					this.items = response.data.data.data
				});
			}
		}
	})

</script>

<style lang="scss">
</style>